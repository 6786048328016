import { Accordion, SimpleGrid, Table } from "@mantine/core";
import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { useMediaQuery } from "react-responsive";

export const TechnicalSkills : React.FC = () => {

    const skillsets = (useStaticQuery(graphql`
        query AllSkillsets {
            allContentfulSkillset(sort: {fields: skills___skillset___title}) {
                nodes{
                    title
                    skills {
                         name
                    }
                }
            }
        }
    `) as Queries.AllSkillsetsQuery)?.allContentfulSkillset?.nodes;

    return (
        <Accordion>
            {skillsets.map((skillset,index) => {
                return (
                    <SkillsetItem key={index} skillSet={skillset} />
                );
            })}
        </Accordion>
    )
}

const SkillsetItem : React.FC<{skillSet: Queries.AllSkillsetsQuery["allContentfulSkillset"]["nodes"][0]}> = ({skillSet}) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
    const columns = isTabletOrMobile ? 1 : 4;

    const title = skillSet.title;
    const ariaTitle = title?.replace(/[, ]/g, "") ?? "";

    return (
        <Accordion.Item value={ariaTitle}>
            <Accordion.Control>{title}</Accordion.Control>
            <Accordion.Panel>
                <SimpleGrid cols={columns}>
                    {skillSet?.skills
                    ?.filter(x => x != null)
                    ?.map((skill, index) => {
                        return (
                            <span key={index}>{skill!.name}</span>
                        );
                    })}
                </SimpleGrid>
            </Accordion.Panel>
        </Accordion.Item>
    );
}