import { Link } from "gatsby";
import * as React from "react";

import "./ShowcaseItem.scss";
import { ShowCaseItemVideo } from "./ShowcaseItemVideo";

type Props = {
    link: string
    title: string

    videoUrl: string
}
export const ShowcaseItem: React.FC<Props> = ({ link, title, videoUrl, children }) => {
    return (
        <div className="showcase__container">
            <div className="showcase__divider"></div>
            <section className="showcase__item">
                <div className="showcase__item__info">
                    <h2 className="showcase__item__title">
                        <Link className="showcase__item__link" to={link} title={title}>
                            {title}
                        </Link>
                    </h2>
                    <section className="showcase__item__description">
                        {children}
                    </section>
                    <footer className="showcase__item__footer">
                        <Link className="showcase__item__link" to={link} title={title}>
                            View {title} {">>"}
                        </Link>
                    </footer>
                </div>
                <div className="showcase__item__example">
                    <Link className="showcase__item__link" to={link} title={title}>
                        <div className="showcase__item__video">
                            <ShowCaseItemVideo title={title} videoUrl={videoUrl} />
                        </div>
                    </Link>
                </div>
            </section>
        </div>
    )
}