import * as React from "react";
import SEO from "../components/layout/seo";

import "./index.scss";
import { ShowcaseItem } from "../components/showcase/ShowcaseItem";
import { Accordion, Text, Timeline } from "@mantine/core";
import { TechnicalSkills } from "../components/showcase/TechnicalSkills";
import { Projects } from "../components/showcase/Projects";
import { StaticImage } from "gatsby-plugin-image"

const RootPage: React.FC = () => {
  return (
    <section className="showcase">

      <SEO 
        title="Cameron McDonald" 
        image="/images/seo/home.png"
        description="Developer and Team Lead."
      />

      <header className="showcase__header">
        <aside>
          <StaticImage
            src="../../static/images/resumepic.jpg" 
            alt="Great person you should hire." 
            placeholder="none"
            layout="fixed"
          />
        </aside>

        <div>
          <h1>Cameron McDonald</h1>
          <p>
            Team Lead and Full Stack Developer with 9 years of professional experience at a digital agency.
            <br />
            <br />
            Can architect, develop and host entire applications; and communicate deliverables and project state to all stakeholders.
          </p>
        </div>
      </header>


      <Projects />


      <h2>Experience</h2>
      <div>
        My work days involve:
        <ol>
          <li>Planning and scoping projects with project and account managers.</li>
          <li>Weekly reports to CTO and COO.</li>
          <li>Assisting team with their projects, setting up and architecting new projects.</li>
          <li>Development work on multiple projects and multiple stacks, primarily Microsoft: C#, .NET Core.</li>
          <li>Creation and update of Cloud architecture, primarily Azure.</li>
          <li>DevOps Pipelines setup and maintenance.</li>
          <li>Meeting with clients to provide updates, deal with issues, and plan projects.</li>
        </ol>
      </div>
      <StaticImage
            src="../../static/images/nexus.png" 
            alt="Great Developer you should Hire" 
            placeholder="blurred"
          />


      <h3>Timeline</h3>
      <Timeline>
      <Timeline.Item title="Working with you.">
          <Text>Future</Text>
          <Text></Text>
        </Timeline.Item>
        <Timeline.Item active title="Contract Senior Developer">
          <Text>Present</Text>
          <Text></Text>
        </Timeline.Item>
        <Timeline.Item title="Director of Production">
          <Text>2018-2021</Text>
          <Text></Text>
        </Timeline.Item>
        <Timeline.Item title="Software Developer">
          <Text>2017-2018</Text>
          <Text></Text>
        </Timeline.Item>
        <Timeline.Item title="Co-op, Software Developer">
          <Text>2013-2016</Text>
          <Text></Text>
        </Timeline.Item>
      </Timeline>
      <h2>Clients</h2>
      <div>
        Fullstack Web Developer with 9 years experience at digital agencies. <br />
        Professional clients have included:
        <ul>
          <li>International Banks.</li>
          <li>International Tourism Boards.</li>
          <li>Major Entertainment Companies.</li>
          <li>Professional Regulatory Boards.</li>
          <li>Lotteries.</li>
          <li>Multiple Startups.</li>
        </ul>
      </div>
      <h2>Technical Skills</h2>
      <div>
        I believe choosing the best technology for the job is ideal,
        but also understand the value in a consistent stack for a team and company.
        <br />
        <br />
        I have experience with multiple languages and frameworks, such as Go and Python. <br />
        But, my professional experience has been mainly with .NET, Azure, and React. 
        <br />
        <br />
        Below are the technicals I have used the majority of my professional career.
        It is primarily Microsoft based: .NET , Azure, Typescript.
        <TechnicalSkills />

      </div>

    </section>
  );
}

export default RootPage;