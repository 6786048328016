import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { ShowcaseItem } from "./ShowcaseItem";

import "./Projects.scss";
import { Accordion } from "@mantine/core";

export const Projects : React.FC = () => {
    return (
        <div className="projects">

         <h2>Projects</h2>
        <p>
          Below are toy projects to explore, enhance and demonstrate my skillset. <br />
          While they are using one stack in particular, I have years of professional experience with .NET, and Azure.
        </p>

        <Accordion>
          <Accordion.Item value="code">
            <Accordion.Control>Code, Architecture</Accordion.Control>
            <Accordion.Panel>
              <StaticImage 
                src="../../../static/images/toyprojectDiagram.png"
                alt="Diagram of Toy Projects. Typescript, React, GraphQL among others mentioned."
                placeholder="blurred"
              />

              <ol>
                <li>The front-end is Typescript, GatsbyJS, React. With WebGL via ThreeJS as well. </li>
                <li>Hosting and DevOps with Netlify.</li>
                <li>Styling done with CSS-in-JS and Sass/SCSS</li>
                <li>Backend is an Apollo Server Serverless API.</li>
                <li>Integration with multiple APIs and services including OpenAI and FaunaDB.</li>
                <li>State management is done with Redux.</li>
              </ol>
              </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
        <ul className="showcase__list">
          <li>
            <ShowcaseItem
              title="Star Stories"
              link="/galaxy"
              videoUrl="https://fast.wistia.net/embed/iframe/6nyqyavu37?seo=false&videoFoam=true"
            >
              <p>
                Explore a galaxy full of cultures and history.<br />
                Each planet's name, history, and culture is generated with OpenAI.
              </p>
            </ShowcaseItem>
          </li>
          <li>
            <ShowcaseItem
              title="Town Tales"
              link="/town"
              videoUrl="https://fast.wistia.net/embed/iframe/79xnc95v9u?seo=false&videoFoam=true"
            >
              <p>
                Cozy times with a cast of characters. <br />
                Your clock determines the scene: day, night, and in between.
              </p>
            </ShowcaseItem>
          </li>
          <li>
            <ShowcaseItem
              title="Skyways"
              link="/skyway"
              videoUrl="https://fast.wistia.net/embed/iframe/hrlkoazlq7?seo=false&videoFoam=true"
            >
              <p>
                Life is a skyway. <br />
                View active flights between 2 airports. <br />
                And just view our planet at night.
              </p>
          
            </ShowcaseItem>
          </li>
        </ul>
        </div>
    );
}