import * as React from "react";
import {ErrorBoundary} from "../layout/errorBoundary";

type Props = {
    videoUrl: string
    title: string
}

const ShowCaseItemVideoComponent : React.FC<Props> = ({title, videoUrl}) => {

    const [showVideo, setShowVideo] = React.useState(false);

    React.useLayoutEffect(() => {
        setShowVideo(true);
    },[]);

    if(!showVideo) return <></>;

    return (
         <iframe 
            src={videoUrl} 
            title={title} 
            allow="autoplay; fullscreen" 
            allowtransparency="true" 
            scrolling="no" 
            className="wistia_embed" 
            name="wistia_embed" 
            msallowfullscreen="true"
            width="100%" 
            height="100%"
        >
        </iframe> 
    );
}

export const  ShowCaseItemVideo : React.FC<Props> = (props) => {
    return (
        <ErrorBoundary>
            <React.Suspense fallback={null}>
                <ShowCaseItemVideoComponent {...props} />
            </React.Suspense>
        </ErrorBoundary>

    )
}